
import { defineComponent, ref, computed, reactive, onMounted, watchEffect } from 'vue'
import { store } from "@/store";
import { FormInstance } from 'element-plus';
import Customers from '@/core/services/Callable/Customers';
import Validations from '@/core/services/etc/Validations';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from '@/router/clean';
import { etc, rule, notification, common } from '@/store/stateless';
import { healthTop, healthMiddle, healthLast } from '@/core/utils'
import Etcs from '@/core/services/Callable/Etcs';
import { ElLoading } from 'element-plus'

export default defineComponent({
    setup() {
        const ruleFormRef = ref<FormInstance>()

        const health = computed(() => {
            return store.state.customer.health;
        })

        const healthDef = ref({
            id: null,
            name: null,
            email: null,
            contact_person: null,
            phone_number: null,
            phone_country_code: "+961",
            other_contact_info:null,
            insured_city: null,
            insured_type_id: 0,
            insurance_type: 0,
            is_salary: null,
            expected_insurance_start_date: null,
            customer_source: null,
            is_health: 1,
            utm_source: null,
            utm_medium: null,
            utm_campaign: null,
            utm_content: null,
            dob: null,
            nationality_id: null,
            gender: null,
            is_married: false,
            company_name: null,
            is_self: null,
            agent: null,
            health_lead_map_id: 0,
            members: [{
                id: null,
                member_name: null,
                relationship: null,
                gender: null,
                dob: null,
                nationality_id: null,
                is_salary: null,
                declaration_answers: [],
                is_married: false
            }],
            categories: [{
                id: null,
                name: null,
                groups: [{
                    id: null,
                    insurance_provider_id: null,
                    insurance_providers: store.state.etc.providers,
                    insurance_policy_id: null,
                    insurance_policies: [],
                    group_count: 1,
                    visa_emirates: null
                }]
            }]
        })

        const loading = computed(() => {
            return store.state.customer.detailLoading
        })

        const clickLoading = computed(() => {
            return store.state.customer.loading
        })

        const sendDeclarations = () => {
            console.log(store.state.customer.leadInformation)
        }

        const tablDisable = ref<boolean>(true)

        const activeName = ref('first')

        const reset = async() => {
            Customers.setHealth(healthDef.value)
            ruleFormRef.value?.clearValidate()
            
        }
        // console.log(health.value)
        const rules = reactive({
            name: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
            email: [{ validator: Validations.email, trigger: ['blur', 'change'] }],
            phone_number: [{ validator: Validations.phone_number, trigger: ['blur', 'change'] }],
            dob: [{ validator: Validations.dob, trigger: ['blur', 'change'] }],
            gender: [{ validator: Validations.gender, trigger: ['blur', 'change'] }],
            is_salary: [{ validator: Validations.is_salary, trigger: ['blur', 'change'] }],
            is_self: [{ validator: Validations.is_self, trigger: ['blur', 'change'] }],
            age_group: [{ validator: Validations.age_group, trigger: ['blur', 'change'] }],
            insured_city: [{ validator: Validations.insured_city, trigger: ['blur', 'change'] }],
            // expected_insurance_start_date: [{ validator: Validations.expected_insurance_start_date, trigger: ['blur', 'change'] }],
            insured_type_id: [{ validator: Validations.insured_type_id, trigger: ['blur', 'change'] }],
            insurance_type: [{ validator: Validations.insurance_type, trigger: ['blur', 'change'] }],
            company_name: [{ validator: Validations.company_name, trigger: ['blur', 'change'] }],
            nationality_id: [{ validator: Validations.nationality, trigger: ['blur', 'change'] }],
            // agent: [{ validator: Validations.agent, trigger: ['blur', 'change'] }]
        })

        const revalidate = () => {
            // ruleFormRef.value?.clearValidate()
            // if(isSubmitted.value) {
            //     ruleFormRef.value?.validate((valid) => {
            //         if(!valid) {
            //             // 
            //         }
            //     })
            // }
        }

        const isSubmitted = ref(false)
        const top = ref()
        const middle = ref()
        const last = ref()
        const familyRef = ref()
        const submitHealth = async (formEl: FormInstance | undefined) => {
            // console.log(health.value)
            isSubmitted.value = true
            if (!formEl) return
            formEl.validate(async (valid, fields: any) => {
                if (valid) {
                    health.value.members = health.value.members.reverse(); 
                    const result = await Customers.updateLead(health.value);
                    if (result.status == 200) {
                        notification.success('Customer Save', 'Customer has been save successfully')

                        await reset();
                        formEl.resetFields();
                        
                        if (!store.getters.showByRole([1])) health.value.agent = store.getters.myId
                        await Customers.details({ customer_id: result.data.data.customer.id })
                        await goLists(result.data.data.customer.id);
                    } else {
                        const { email_taken, phone_taken } = result.response.data.data
                        Validations.email_taken = email_taken
                        Validations.phone_taken = phone_taken
                        formEl.validate(async (valid) => {
                        })

                    }
                } else {
                    rule.customerDetails = Object.keys(fields)[0]
                    console.log('rules',rule.customerDetails)
                    if (healthTop.includes(rule.customerDetails)) top.value.focus();
                    else if (healthMiddle.includes(rule.customerDetails)) middle.value.focus();
                    else if (healthLast.includes(rule.customerDetails)) last.value.focus();
                    else if(familyRef.value) familyRef.value.focus();
                }
            })
        }

        const submitDeclarations = async () => {
            const result = await Customers.declarations({
                lead_id: store.state.customer.leadId,
                lead_information: store.state.customer.leadInformation
            });
            if (result.status == 200) {
                notification.success(
                    'Health Declaration',
                    'Declaration successfully save'
                );
            } else {
                notification.warning(
                    'Health - Error',
                    result.response.data.message,
                );
            }
        }

        const addNewMember = () => {
            // console.log('new member')
            let relationship = <any>null
            if(health.value.insured_type_id == 7) relationship = "Referral"
            health.value?.members?.unshift({
                id: null,
                insured_type_id: null,
                insured_city: null,
                member_name: null,
                relationship,
                gender: null,
                dob: '',
                nationality_id: null,
                is_salary: null,
                declaration_answers: [],
                is_married: false
            })
        }

        onMounted(() => {
            const elLoadingS = ElLoading.service({
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.1)',
            })
            setTimeout(() => {
                elLoadingS.close()
            },300)

            reset()

            setCurrentPageBreadcrumbs("Add User Details", [{ label: "Leads & Tasks", value: "CustomerLists" }]);

            if (!store.getters.showByRole([1])) health.value.agent = store.getters.myId

            health.value.expected_insurance_start_date = common.getToday()
           
            

            setTimeout(() => {
                    console.log('check in')
                    rule.customerDetails = 'phone_number'
                    top.value.focus();
            },300)

        })

        const goBack = () => {
            activeName.value = 'first';
        }

        const goLists = async (customer_id: number) => {
            await router.replace({ name: 'CustomerDetails', params: { customer_id } })
        }

        watchEffect(() => {
            if (health.value != undefined && health.value != null) {
                if (health.value.name && health.value.is_self && health.value.insured_type_id === 3) {
                    // const index = health.value.members.filter(x => x.relationship === 'Sponsor')[0];
                    // index.member_name = health.value.name;
                    // index.gender = health.value.gender;
                    // index.dob = health.value.dob;
                    // index.nationality_id = health.value.nationality_id;
                }

            }
        })

        const checkform = () => {
            const myForm: any = document.querySelectorAll('#healthTop')
            console.log(myForm)
        }

        const checkPhoneExist = async() => {
            if (health.value.phone_number) {
                const data = await Etcs.checkExistNumber({
                    phone_country_code: health.value.phone_country_code,
                    phone_number: health.value.phone_number,
                    phoneNo:health.value.phone_number,
                    country: health.value.phone_country_code,
                });

                if(data && (data.is_health == 0 || !data.any_health_lead)){
                    health.value.name = data.name;
                    health.value.email = data.email;
                }
                // console.log(data);
            }
        }

        

        return {
            etc,
            top,
            familyRef,
            last,
            rule,
            store,
            rules,
            middle,
            health,
            loading,
            activeName,
            tablDisable,
            ruleFormRef,
            clickLoading,
            goBack,
            goLists,
            checkform,
            revalidate,
            submitHealth,
            addNewMember,
            sendDeclarations,
            submitDeclarations,
            checkPhoneExist
        }
    },
})
