import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-toolbar" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = {
  class: "card card-bordered mb-5",
  id: "familyRef"
}
const _hoisted_6 = { class: "card-header ribbon mb-5" }
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = { class: "card-footer" }
const _hoisted_9 = { class: "container" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-sm-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_button_navigator = _resolveComponent("vc-button-navigator")!
  const _component_vc_health_top = _resolveComponent("vc-health-top")!
  const _component_vc_health_middle = _resolveComponent("vc-health-middle")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_vc_health_family = _resolveComponent("vc-health-family")!
  const _component_vc_health_last = _resolveComponent("vc-health-last")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "card-title" }, "New User", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_vc_button_navigator, {
          text: "Back",
          value: "CustomerLists",
          "class-name": "btn btn-sm btn-flex btn-info btn-active-info fw-bolder",
          "is-icon": ""
        })
      ])
    ]),
    _createVNode(_component_el_form, {
      ref: "ruleFormRef",
      model: _ctx.health,
      "status-icon": "",
      rules: _ctx.rules,
      "scroll-to-error": "",
      id: "healthForm",
      onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.submitHealth(_ctx.ruleFormRef)), ["prevent"]))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_vc_health_top, {
            ref: "top",
            onReValidate: _ctx.revalidate,
            onTab: _ctx.checkform,
            onCheckPhoneExist: _ctx.checkPhoneExist
          }, null, 8, ["onReValidate", "onTab", "onCheckPhoneExist"]),
          _createVNode(_component_vc_health_middle, {
            ref: "middle",
            onReValidate: _ctx.revalidate,
            hideEmployeeType: true
          }, null, 8, ["onReValidate"]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "card-title mb-5 mt-5" }, [
                _createElementVNode("h6", { class: "card-subtitle text-muted" }, " Policy Holder Details ")
              ], -1)),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_el_button, {
                  type: "button",
                  class: "btn btn-lg btn-info w-10 h-auto",
                  onClick: _ctx.addNewMember
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Add Member ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _createVNode(_component_vc_health_family, {
              ref: "familyRef",
              onReValidate: _ctx.revalidate
            }, null, 8, ["onReValidate"])
          ]),
          (_ctx.store.getters.showByRole([1]))
            ? (_openBlock(), _createBlock(_component_vc_health_last, {
                key: 0,
                ref: "last"
              }, null, 512))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_vc_button_navigator, { value: "CustomerLists" }),
                _createVNode(_component_vc_button_save, { loading: _ctx.clickLoading }, null, 8, ["loading"])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}